import React, { Component } from 'react';
import TableFunctions from '../datatables/api/TableFunctions.js';
import ReactTable from "react-table-6";
import Draggable from "react-draggable";
import TextField from '@mui/material/TextField';
import dateFormat from 'dateformat';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, } from 'chart.js';
import { Chart, Scatter, Line } from 'react-chartjs-2'; //  Bar,
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP";
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';
import Info from "../datatables/api/Info";
import { format } from 'date-fns';
import imgTrash from '../datatables/img/trash.png';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend,);

function t(field) { return Translation.get(field) }

class Edit extends TableFunctions {
  constructor() {
    super()
    this.state = {
      getEventUrl: "/charts/get_events.php?type=0",
      saveurl: "/robots/set_drives.php?robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      eventUrl: "/robots/set_event.php",
      animalGroup: 0,
      eventName: "", date: dateFormat(new Date(), "yyyy-mm-dd"), eventId: "0", extradrive: 100, events: [t("event_additional"), t("event_claws"), t("event_herbage"), t("event_backweight")], delEvent: null, // event data
      data: null, empty: null, selected: null, // Table data
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      edit: false,
      position: [],
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleEventDelete = this.handleEventDelete.bind(this)
  }
  handleClose() {
    this.props.closeDiv();
  }
  storeEvent(event) {
    event.preventDefault();
    let id = this.state.eventId
    if (id === "0") id = "4"
    //console.log(this.state.eventUrl + "?id=" + this.state.animalGroup + "&evid="+ id + "&val=" + this.state.extradrive + "&name=" + this.state.eventName + "&date=" + this.state.date)
    GetJsonFormPHP(this.state.eventUrl + "?id=" + this.state.animalGroup + "&evid="+ id + "&val=" + this.state.extradrive + "&name=" + this.state.eventName + "&date=" + this.state.date).then((result) => {
      this.props.reload()
    })
  }
  static getDerivedStateFromProps(props, state) {
    return {
      empty: props.empty,
      data: props.data,
      animalGroup: props.animalGroup,
      position: props.position,
    }
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_box")
    e1.style.left = (this.state.position[0]-28*em)+'px';
    e1.style.top =(this.state.position[1]-10*em)+'px';
  }
  reload() {
    this.props.reload()
  }
  handleEventDelete = (id) =>{
    GetJsonFormPHP(this.state.eventUrl +"?delID=" + id).then((result) => {
      this.reload()
    })
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    return [
      { accessor: 'id', show: false, },
      this.addTextCell('date', 'timestamp', 0, 5, true, "", "right", null, null, false),
      this.addTextCell('name', 'name', 0, 12, true, "", null, null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 5*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          return <img src={imgTrash} alt={t("delete")} title={t("delete")} onClick={() => { this.handleEventDelete(row.id) } } width={em + "px"}/>
        },
      },
    ]
  }

  render() {
    const saveEvent = value => { this.handleClose() }
    const changeEvent = value => { this.setState({ eventId: value.target.value, }) }
    const changeVal = value => { this.setState({ extradrive: value.target.value, }) }
    const columnsEvents = this.createColumns()
    return (
      <Draggable handle=".handle">
      <div className="edittable" id="edit_box">
        <div className="header">
          <div className="header_inline handle">{t('animalgroup_add_event')}</div>
          <div className="header_inline close"><img src={require("../datatables/img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/close.png")}} /></div>
        </div>
        <div align ="center">
          <br />
          <form onSubmit={saveEvent}>
            <div className="table_buttons">
              <select name="add_event" value={this.state.eventId} onChange={changeEvent} className="table_input">
                { Object.entries(this.state.events).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) }
              </select> <br />

              { this.state.eventId === "0" ? <span>{t('name')}: <input type="text" name="event_name" value={this.state.eventName} onChange={(value) => { this.setState({ eventName: value.target.value, }) }} className="" /> &nbsp; &nbsp; </span> : null}
              { this.state.eventId === "3" ? <span>{t("animals_feedamount")}: <input type="text" name="event_val" value={this.state.extradrive} onChange={changeVal} className="right_align"/>{this.state.event === "0" ? "%" : "kg"} &nbsp; &nbsp; </span> : null}
              <span>{t("date")}: <TextField id="event_date" type="date" defaultValue={this.state.date} InputLabelProps={{ shrink: false, }} onChange={ (value) => { this.setState({ date: value.target.value, }) }} className="dateInput"/></span>
            </div>
            <div className="table_buttons" align="center">
              <button onClick={(value) => { this.storeEvent(value) }} disabled={this.state.eventName.length === 0 && this.state.eventId === "0" ? true : false }className="button">{t("save")}</button>
              <button onClick={this.handleClose} className="button">{t("cancel")}</button>
            </div>
          </form>
        </div>
        {this.state.data !== null ?
        <ReactTable
            data={this.state.data}
            columns={columnsEvents}
            minRows = {0}
            previousText= {'<'}
            nextText= {'>'}
            useFlexLayout = {true}
            showPaginationBottom={false}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                  style: {
                    background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white',
                    color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.id < 0 ? 'red' : 'black'
                  }
              }
            }}
        /> : null}
      </div>
      </Draggable>
    )
  }
}

class ChartAnimalGroup extends Component{
  constructor() {
    super()
    let startDay = new Date()
    startDay.setDate(startDay.getDate()-31)
    this.state={
      geturl: "/charts/animalgroup.php?group_id=",
      getEventUrl: "/charts/get_events.php?type=0",
      data: [],
      label: [],
      feeds: null,
      animalgroups: null,
      startDate: format(startDay, 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      selected: 0,
      selectedGroup: 0,
      fixScale : true,
      maxAmount: 100,
      minAmount: -5,
      maxMilkAmount: 50,
      showScale: { milk: true, percent: false, gramm: false, price: true },
      nutrids: "0",
      TM: "0",
      weightBack: false,
      eventList: [], eventEmpty: [],  // event list for edit
      showHelp: false, showHelpId: null, showPosition: [], // Info popup
    }
    this.getData = this.getData.bind(this)
    this.reload = this.reload.bind(this)
    this.closeEdit = this.closeEdit.bind(this)
  }
  componentDidMount() {
    let group = this.state.selectedGroup
    if (window.sessionStorage.getItem("animalgroup_id") !== 0 && this.state.selectedGroup === 0) {
      this.setState({
        selectedGroup: window.sessionStorage.getItem("animalgroup_id"),
      })
      group = window.sessionStorage.getItem("animalgroup_id")
    }
    GetJsonFormPHP(this.state.geturl + group + "&start=" + this.state.startDate + "&end=" + this.state.endDate + "&TM=0").then((result) => {
      this.setState({
        data: result.data,
        label: result.label,
        feeds: result.feeds,
        animalgroups: result.animalgroups,
        maxAmount: result.max[0],
        minAmount: result.max[1],
        maxMilkAmount: result.max[2],
        showScale: result.scales,
        selectedGroup: result.group,
      })
      GetJsonFormPHP(this.state.getEventUrl + "&group_id=" + result.group).then((result) => {
        this.setState({
          eventList: result.data,
          eventEmpty: result.empty,
        })
      })
    })
  }
  reload() {
    this.setState({
      showHideEdit: false,
    })
    this.componentDidMount()
  }
  closeEdit() {
    this.setState({
      showHideEdit: false,
    })
  }
  setfeedType(feed) {
    this.setState({ selected: parseInt(feed), })
  }
  getData(group, startDate = this.state.startDate, endDate = this.state.endDate, nutrids = this.state.nutrids, TM = this.state.TM, weightBack = this.state.weightBack) {
    window.sessionStorage.setItem("animalgroup_id", group)
    GetJsonFormPHP(this.state.geturl + group + "&start=" + startDate + "&end=" + endDate + "&nutrids=" + nutrids + "&TM=" + TM + "&wBack=" + weightBack).then((result) => {
      this.setState({
        data: result.data,
        label: result.label,
        feeds: result.feeds,
        animalgroups: result.animalgroups,
        maxAmount: result.max[0],
        minAmount: result.max[1],
        maxMilkAmount: result.max[2],
        selectedGroup: group,
        showScale: result.scales,
        nutrids: nutrids,
      })
      GetJsonFormPHP(this.state.getEventUrl + "&group_id=" + this.state.selectedGroup).then((result) => {
        this.setState({
          eventList: result.data,
          eventEmpty: result.empty,
        })
      })
    })
  }
  getOptionsChartFix() {
    return {
      plugins: {
        title: {
          display: true,
          text: t("chart_feeds_per_day"),
          font: { size: 24, },
        },
        legend: {
          position: 'bottom',
          //align: 'start',  // Cenrter - start - end
          labels: {
            font: { size: 16, }, // This more specific font property overrides the global property
            textAlign: 'left',
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';
                if (label) { label += ': '; }
                if (context.parsed.y !== null) {
                  //console.log(context.dataset)
                  if (context.parsed.y>0) label += context.parsed.y + " " + context.dataset.unit;
                  else {
                    var d = context.dataset
                    var index = context.dataIndex
                    if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                  }
                }
                return label
            },
            afterTitle: function(data) {
              var d = data[0].dataset
              var index = data[0].dataIndex
              if (d.event && d.event[index] !== "" && d.data[index] >= 0) {
                var events = d.event[index].split('|')
                return events
              }
              return null
            },
          },
          //bodyFontStyle: 'bold',
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
          //titleFontSize: 35,
          //bodyFontSize: 28,
          //borderColor: '#FF9999',
          //multiKeyBackground: "#ffffff"
        },
      },
      elements: {
        line: { tension: 0.4 }
      },
      responsive: true,
      categoryPercentage: 0.95,
      barPercentage: 0.9,
      scales: {
        x: { stacked: true, },
        /*xScales: {
          stacked: true,
          //position: "none",
          //categoryPercentage: 1.2,
          //barPercentage: 0.1,
        },*/
        y: {
          stacked: true,
          min: this.state.minAmount,
          max: this.state.maxAmount,
          grid: {
            display: true,
            color: 'rgba(0, 0, 0, 0.3)',
          },
          ticks: { callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' kg'  // '●' +
            else return ""
          } }
        },
        y1: {
          display: this.state.showScale.milk,
          min: this.state.minAmount*this.state.maxMilkAmount/this.state.maxAmount,
          max: this.state.maxMilkAmount,
          position: 'right',
          grid: {
            display: true,
            color: 'rgba(0, 0, 255, 0.4)',
            borderDash: [5,5],
            lineWidth: 0.5
          },
          ticks: {
            color: 'blue',
            callback: function(value, index, ticks) {
              //console.log(value, index, ticks)
              if (value >= 0 ) return value + ' l' // '◆' +
            else return ""
          } },
        },
        y2: {
          //display: this.state.showScale.milk,
          min: 0,
          position: 'none',
          grid: {
            display: false,
          },
          ticks: {
            color: 'blue',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return value + 'x' // '◆' +
            else return ""
          } },
        },
        y3: {
          //in: 0,
          position: 'none',
          grid: {
            display: false,
          },
          ticks: {
            color: 'maroon',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return value + '°C' // '◆' +
            else return ""
          } },
        },
      }
    }
  }
  getOptionsChartVar() {
    return {
      plugins: {
        title: {
          display: true,
          text: t("chart_feeds_per_day"),
          font: { size: 24, },
        },
        legend: {
          position: 'bottom',
          labels: {
            font: { size: 16, } // This more specific font property overrides the global property
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';
                if (label) { label += ': '; }
                if (context.parsed.y !== null) {
                  if (context.parsed.y>0) label += context.parsed.y + " " + context.dataset.unit;
                  else {
                    var d = context.dataset
                    var index = context.dataIndex
                    if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                  }
                }
                return label
            },
            afterTitle: function(data) {
              var d = data[0].dataset
              var index = data[0].dataIndex
              if (d.event && d.event[index] !== "" && d.data[index] >= 0) {
                var events = d.event[index].split('|')
                return events
              }
              return null
            },
          },
          //bodyFontStyle: 'bold',
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
          //titleFontSize: 35,
          //bodyFontSize: 28,
          //borderColor: '#FF9999',
          //multiKeyBackground: "#ffffff"
        },
      },
      elements: {
        line: { tension: 0.4 }
      },
      categoryPercentage: 0.95,
      barPercentage: 0.9,
      responsive: true,
      scales: {
        x: { stacked: true,  },
        y: {
          stacked: true,
          grid: { display: true, color: 'rgba(0, 0, 0, 0.3)', },
          ticks: { callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' kg'
            else return ""
          } },
        },
        y1: {
          display: this.state.showScale.milk,
          position: 'right',
          grid: {
            display: true,
            color: 'rgba(0, 0, 255, 0.4)',
            borderDash: [5,5],
            lineWidth: 0.5
          },
          ticks: {
            color: 'blue',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return value + ' l'
              else return ""
            }
          },
        },
        y2: {
          min: 0,
          position: 'right',
          grid: { display: false, },
          ticks: {
            color: 'black',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return value + 'x' // '◆' +
              else return ""
            }
          },
        },
        y3: {
          //in: 0,
          position: 'right',
          grid: { display: true, color: 'rgba(255, 0, 0, 0.5)', borderDash: [5,5], lineWidth: 0.5 },
          ticks: {
            color: 'maroon',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return value + '°C' // '◆' +
            else return ""
          } },
        },
      },
    }
  }
  getOptionsChartNutrid() {
    return {
      plugins: {
        title: {
          display: true,
          text: t("chart_feeds_nutrids"),
          font: { size: 24, },
        },
        legend: {
          position: 'right',
          labels: {
            font: { size: 14, } // This more specific font property overrides the global property
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                let label = context.dataset.label || ''
                if (label) { label += ': '; }
                if (context.parsed.y !== null) {
                  if (context.parsed.y>0) label += context.parsed.y + " " + context.dataset.unit;
                  else {
                    var d = context.dataset
                    var index = context.dataIndex
                    if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                  }
                }
                return label
            },
            afterTitle: function(data) {
              var d = data[0].dataset
              var index = data[0].dataIndex
              if (d.event && d.event[index] !== "" && d.data[index] >= 0) {
                var events = d.event[index].split('|')
                return events
              }
              return null
            },
          },
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
        },
      },
      elements: {
        line: { tension: 0.4 }
      },
      responsive: true,
      categoryPercentage: 0.95,
      barPercentage: 0.9,
      scales: {
        x: {
          grid: {
            display: false,
            //color: 'rgba(0, 0, 255, 0.2)',
            //borderDash: [5,5],
            //lineWidth: 0.5
          },
          //display: false,
          stacked: true,
          //interlacedColor: "#F0F8FF",
        },
        y: {
          stacked: true,
          ticks: { callback: function(value, index, ticks) {
            if (value >= 0 ) return '●' + value + ' kg'  //
            else return ""
            }
          }
        },
        y1: {
          display: this.state.showScale.milk,
          min: 0,
          max: this.state.maxMilkAmount,
          grid: { display: true, color: 'rgba(0, 0, 255, 0.2)', borderDash: [5,5], lineWidth: 0.5 },
          position: 'right',
          ticks: {
            color: 'blue',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return '◆' + value + ' l'
              else return ""
            }
          },
        },
        y2: { //  [%] Scala
          display: this.state.showScale.percent,
          stacked: true,
          position: 'right',
          grid: { color: 'rgba(0, 185, 0, 0.3)', borderDash: [2,2], lineWidth: 0.8 },
          ticks: {
            color: 'green',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return '⁕' + value + '%'  //text: '⁎⏴● ⁎', // ⚫ ◀  🌧️ 🌺🌲
              else return ""
            }
          }
        },
        y4: {  // [€] Scala
          display: this.state.showScale.price,
          position: 'right',
          stacked: true,
          grid: { color: 'rgba(185, 150, 0, 0.2)', borderDash: [3,3,1,3], lineWidth: 0.5 },
          ticks: {
            color: 'rgba(158, 140, 59, 0.9)',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return value + '€'  //   ⏶' text: '⏴● ⁎', // ⚫ ◀  🌧️ 📄📆👆👍👎📋💻📝💾🔑🔒🔓🔔🕵📶🕶🔧📧🔙👓👁🎴🍶✔🖉 🖴
              else return ""
            }
          }
        },
        y5: {  // [g] Scala
          display: this.state.showScale.gramm,
          stacked: true,
          grid: { color: 'rgba(185, 0, 0, 0.2)', borderDash: [3,3,1,3], lineWidth: 0.5 },
          ticks: {
            color: 'rgba(205, 80, 80, 0.7)',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return '●' + value + 'g'  //   ⏶' text: '⏴● ⁎', // ⚫ ◀  🌧️ 🖴
              else return ""
            }
          }
        },
      }
    }
  }
  render() {
    //console.log(this.state.selectedGroup)
    const userRight = UserProfile.checkRight("custuser")
    const changeFeed = value => { this.setfeedType(value.target.value) }
    const changeGroup = value => { this.getData(value.target.value) }
    const changeNutrids = value => { this.getData(this.state.selectedGroup, this.state.startDate, this.state.endDate, value.target.value) }
    const startChange = value => { this.setState({ startDate: value.target.value, }) }
    const endChange = value => { this.setState({ endDate: value.target.value, }) }
    const changeTM = value => { this.setState({ TM: value.target.value}); this.getData(this.state.selectedGroup, this.state.startDate, this.state.endDate, this.state.nutrids, value.target.value) }
    const changeWeightBack = value => { this.setState({ weightBack: !this.state.weightBack}); this.getData(this.state.selectedGroup, this.state.startDate, this.state.endDate, this.state.nutrids, this.state.TM, !this.state.weightBack) }
    let options = this.getOptionsChartFix()
    if (this.state.nutrids === "1") options = this.getOptionsChartNutrid()
    else if (!this.state.fixScale) options = this.getOptionsChartVar()
    let data1 = this.state.data
    if (this.state.selected !== 0) data1 = [this.state.data.find((el, i) => i === this.state.selected-1)]
    let labels = this.state.label
    const data = {
      labels,
      datasets: data1,
    }
    labels = ['0', '1']
    const data2 = {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          fill: false,
          data: [1,2],
        },
      ],
    }
    //{this.state.animalgroups !== null ? Object.entries(this.state.animalgroups).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>): null }
    return (
      <div>
        <table width="100%" border="0">
          <thead><tr>
            <td>{t("choose")} <select name="nutridslist" value={this.state.nutrids} onChange={(value) => {changeNutrids(value)}} className="chartselect">
                <option key="nutrid_off" value="0">{t("chart_select_feed")}</option>
                <option key="nutrid_on" value="1">{t("chart_select_nutrids")}</option>
              </select></td>
            <td>{t("chart_animalgroups")}</td><td>{t("start_date")}</td><td>{t("end_date")}</td>
            <td><nobr><input type="checkbox" id="weight_back" name="weight_back" value="1" checked={this.state.weightBack} onChange={changeWeightBack} />{t('include')} {t('event_backweight')}</nobr></td><td> </td><td> </td><td> </td>
          </tr></thead>
          <tbody><tr>
            <td>
              <select name="feedlist" value={this.state.selected} onChange={(value) => {changeFeed(value)}} style={null} className="chartselect">
                { (this.state.feeds !== null) ? Array.isArray(this.state.feeds) ? Object.entries(this.state.feeds).map((t,k) => <option key={k} value={t[1].value} style={ t[1].color ? { color: t[1].color, } : null }>{t[1].label}</option>)
                  : Object.entries(this.state.feeds).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) : null}
              </select>
            </td>
            <td>
              <select name="grouplist" value={this.state.selectedGroup} onChange={(value) => {changeGroup(value)}} className="chartselect">
              { (this.state.animalgroups !== null) ? Array.isArray(this.state.animalgroups) ? Object.entries(this.state.animalgroups).map((t,k) => <option key={k} value={t[1].value} style={ t[1].color ? { color: t[1].color, backgroundColor: t[1].bgcolor, fontWeight: t[1].weight} : null }>{t[1].label}</option>)
                  : Object.entries(this.state.animalgroups).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) : null}
              </select>
            </td>
            <td><TextField id="start_date" type="date" defaultValue={this.state.startDate} InputLabelProps={{ shrink: true,}} onChange={startChange} className="datefield"/></td>
            <td><TextField id="end_date" type="date" defaultValue={this.state.endDate} InputLabelProps={{ shrink: true,}} onChange={endChange} className="datefield"/></td>
            <td>
              <nobr><input type="radio" id="FM" name="TM" value="0" checked={this.state.TM === "0" ? true : false} onChange={changeTM} /><label htmlFor="FM">{t('feed_FM')}  ({t('feed_FM_short')})</label></nobr><br />
              <nobr><input type="radio" id="TM" name="TM" value="1" checked={this.state.TM === "1" ? true : false} onChange={changeTM} /><label htmlFor="TM">{t('feed_T')} ({t('feed_T_short')})</label></nobr>
            </td>
            <td align="center"><nobr>{this.state.nutrids === "0" ? <span><input id="fix_scale" type="checkbox"defaultChecked={this.state.fixScale}  onChange={() => {this.setState({ fixScale: !this.state.fixScale, })}}/>{t("chart_fix_scale")}</span> : null }</nobr></td>
            <td width="15%" align="center"><button onClick={() => {this.getData(this.state.selectedGroup, this.state.startDate, this.state.endDate)}} className="button">{t("show")}</button></td>
            <td><div className="table_buttons"><div className="right_side"><img id="info" src={require("../datatables/img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_animals_chart", }) } } onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/info.png")}} /></div></div></td>
          </tr></tbody>
        </table>
        <Line data={data2} className="hidden"/>
        <Scatter data={data2} className="hidden"/>
        <Chart type="bar" options={options} data={data} className="stats"/>
        {this.state.showHideEdit && <Edit data={this.state.eventList} empty={this.state.eventEmpty} animalGroup={this.state.selectedGroup} position = {this.state.showPosition} closeDiv={this.closeEdit} reload={this.reload} />}
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        {userRight ? <div align="center"><br /><button onClick={(value) => { this.setState({ showHideEdit: true, showPosition: [value.clientX, value.clientY],})}} className="button">{t("animalgroup_add_event")}</button></div> : null}
      </div>
    )
  }
}

export default ChartAnimalGroup;