var Translation = (function() {
  var getLanguage = function() {
    return localStorage.getItem('language_id')
  }
  var clearTranslations = function() {
    localStorage.setItem('language_id', null)
    localStorage.clear()
  }

  var translation = function(field) {
    if (localStorage.getItem("lang_" + field) !== null) return localStorage.getItem("lang_" + field)
    else return <span className="notranslation">{field}</span>
  }

  var setTranslations = function(lan, fields) {
    localStorage.setItem('language_id', lan)
    for (const [key, value] of Object.entries(fields)) localStorage.setItem('lang_' + key, value)
  }

  return {
    getLanguage: getLanguage,
    get: translation,
    set: setTranslations,
    clear: clearTranslations
  }

})()
/*
function t(field) {
  return localStorage.getItem("lang_" + field)
}*/

export default Translation
